<template>
	<audio src="@/assets/mp3/Tiempo_es_cerebro_Midazolam_Oromucosal.mp3" controls></audio>
</template>

<script>
export default {

}
</script>

<style>

</style>